import React, { useState } from 'react';
import { Route, Switch } from 'react-router';
import './styles/App.css';
import API from './utilities/API';
import SiteHeader from './components/SiteHeader';
import Homepage from './pages/Homepage';
import QuizPage from './pages/quiz/Quiz';
import TeacherPage from './pages/teacher/TeacherPage';
import ClassesPage from './pages/teacher/classes/ClassesPage';
import ClassroomPage from './pages/teacher/classes/classroom/ClassroomPage';
import QuizzesPage from './pages/teacher/quizzes/QuizzesPage';
import QuizAdminPage from './pages/teacher/quizzes/quiz/QuizPage';


export default function App() {
  // variables
  const [api] = useState(new API());

  // return components
  return (
    <div className='awsui'>
      <SiteHeader/>
      <Switch>
        <Route exact path='/quiz/:quizId' render={(props) => <QuizPage {...props} api={api} />} />
        <Route exact path='/teacher' render={(props) => <TeacherPage {...props} api={api} />} />
        <Route exact path='/teacher/classes' render={(props) => <ClassesPage {...props} api={api} />} />
        <Route exact path='/teacher/classes/:classroomId' render={(props) => <ClassroomPage {...props} api={api} />} />
        <Route exact path='/teacher/quizzes' render={(props) => <QuizzesPage {...props} api={api} />} />
        <Route exact path='/teacher/quizzes/:quizId' render={(props) => <QuizAdminPage {...props} api={api} />} />
        <Route render={(props) => <Homepage {...props} api={api} />} />
      </Switch>
    </div>
  );
}