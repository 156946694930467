import React from 'react';
import {
  SideNavigation
} from '@awsui/components-react';

export default function TeacherNavigation(props) {
  // variables

  // functions

  // listeners

  // render
  return (
    <SideNavigation
      header={{ text: 'Teacher', href: '#/teacher' }}
      items={
        [
          { type: 'link', text: 'Classes', href: '#/teacher/classes' },
          { type: 'link', text: 'Quizzes', href: '#/teacher/quizzes' },
        ]
      }
      activeHref={window.location.hash}
    />
  );
}