import React from 'react';
import Utils from '../utilities/StringUtils';


export default class Video extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      uuid : Utils.uuid(),
    }
  }

  render() {
    return (
      <video id={this.state.uuid} style={{ width: '100%' }} controls={window.location.host.split(':')[0] === 'localhost'} autoPlay onEnded={this.props.onEnd} >
        <source src={`https://quizloop-content.s3.amazonaws.com/quizzes/${this.props.videoTitle}`} type="video/mp4" />
      </video>
    );
  }

}