import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppLayout,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  Table,
} from '@awsui/components-react';
import TeacherNavigation from '../TeacherNavigation';

export default function QuizzesPage(props) {
  // variables
  const [classrooms, setClassrooms] = useState([]);
  const [newQuizName, setNewQuizName] = useState(null);
  const [newQuizClassroom, setNewQuizClassroom] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [quizModalVisible, setQuizModalVisible] = useState(false);
  const [quizzes, setQuizzes] = useState([]);
  const [selectedClassroom, setSelectedClassroom] = useState({});

  // functions
  async function getClassData() {
    setClassrooms((await props.api.get({ path: '/classes', api: 'TeacherAPI'})));
  }

  async function getQuizData() {
    setQuizzes((await props.api.get({ path: '/quizzes', api: 'TeacherAPI'})));
  }

  async function addQuiz() {
    // disable buttons
    setProcessing(true);

    // create new quiz object
    const newQuiz = {
      name: newQuizName,
      classroom: newQuizClassroom,
      teacher: 'terrywynn',
    };

    // Create quiz
    await props.api.post({ api: 'TeacherAPI', path: '/quizzes', body: newQuiz });

    // reload quiz list
    await getQuizData();

    // reset new quiz fields
    setNewQuizName(null);
    setNewQuizClassroom(null);
    setSelectedClassroom({});
    setQuizModalVisible(false);

    // enable buttons
    setProcessing(false);
  }

  // hooks
  useEffect(() => {
    getClassData();
    getQuizData();
  }, [])

  useEffect(() => {
    setNewQuizClassroom(selectedClassroom.value);
  }, [selectedClassroom])

  // return component
  return (
    <AppLayout
      content={
        <>
          <Table
            header={
              <Header
                actions={<Button variant='primary' onClick={() => setQuizModalVisible(true)}>Add</Button>}
              >
                Quizzes
              </Header>
            }
            loading={!quizzes.length}
            items={quizzes}
            columnDefinitions={
              [
                {
                  id: 'name',
                  header: 'Quiz name',
                  cell: item => <Link to={`quizzes/${encodeURIComponent(item.id)}`}>{item.name}</Link>,
                },
                {
                  id: 'classroom',
                  header: 'Classroom',
                  cell: item => item.classroom,
                },
                {
                  id: 'id',
                  header: 'ID',
                  cell: item => item.id,
                },
                {
                  id: 'created',
                  header: 'Created',
                  cell: item => new Date(item.createdAt).toLocaleString(),
                },
              ]
            }
          />
          <Modal
            visible={quizModalVisible}
            header='New quiz'
            onDismiss={() => setQuizModalVisible(false)}
            footer={
              <>
                <Button
                  variant='primary'
                  onClick={addQuiz}
                  disabled={!(newQuizName && newQuizClassroom)}
                  loading={processing}
                >
                  Add
                </Button>
              </>
            }
          >
            <ColumnLayout columns={1}>
              <FormField
                label='Name'
              >
                <Input
                  value={newQuizName}
                  onChange={({ detail: { value } }) => setNewQuizName(value)}
                />
              </FormField>
              <FormField
                label='Classroom'
              >
                <Select
                  options={classrooms.map(item => {return {value: `${item.semester}|${item.id}`, label: `${item.semester}|${item.id}`}})}
                  selectedOption={selectedClassroom}
                  onChange={({ detail: { selectedOption } }) => setSelectedClassroom(selectedOption)}
                />
              </FormField>
            </ColumnLayout>
          </Modal>
        </>
      }
      navigation={<TeacherNavigation/>}
      headerSelector='#site-header'
      footerSelector='#site-footer'
      toolsHide={true}
    />
  )
}