import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  AppLayout
} from '@awsui/components-react';

export default function HomePage(props) {
  // variables
  async function test() {
    console.log('starting');
    console.log((await props.api.get({ path: '/quizzes/123-abc'})));
  }

  // test api
  useEffect(() => {
    test();
  }, [])

  // return component
  return (
    <AppLayout
      content={
        <div>Homepage</div>
      }
      headerSelector='#site-header'
      footerSelector='#site-footer'
      toolsHide={true}
      navigationHide={true}
    />
  )
}