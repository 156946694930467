import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  AppLayout,
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Modal,
  Table,
} from '@awsui/components-react';
import TeacherNavigation from '../../TeacherNavigation';

export default function ClassroomPage(props) {
  // variables
  const { classroomId } = useParams();
  const [classroom, setClassroom] = useState({});
  const [newStudentAlias, setNewStudentAlias] = useState(null);
  const [newStudentName, setNewStudentName] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [studentModalVisible, setStudentModalVisible] = useState(false);

  // functions
  async function getClassData() {
    setClassroom((await props.api.get({ path: `/classes/${classroomId}`})));
  }

  async function removeStudent(alias) {
    // disable buttons
    setProcessing(true);

    // find the student to remove
    const index = classroom.students.findIndex(item => item.alias === alias);

    // check that the item is in the array
    if (index >= 0) {
      // remove student
      classroom.students.splice(index, 1);

      // post class change
      setClassroom((await props.api.put({ api: 'TeacherAPI', path: `/classes/${classroomId}`, body: classroom })));
    }

    // enable buttons
    setProcessing(false);
  }

  async function addStudent() {
    // disable buttons
    setProcessing(true);

    // create new student object
    const newStudent = { name: newStudentName, alias: newStudentAlias };

    // update class
    classroom.students.push(newStudent);
    setClassroom((await props.api.put({ api: 'TeacherAPI', path: `/classes/${classroomId}`, body: classroom })));

    // reset new student fields
    setNewStudentAlias(null);
    setNewStudentName(null);
    setStudentModalVisible(false);

    // enable buttons
    setProcessing(false);
  }

  // hooks
  useEffect(() => {
    getClassData();
  }, [])

  // return component
  return (
    <AppLayout
      content={
        <>
          <Container>
            <ColumnLayout columns={2}>
              <FormField
                label='Semester'
              >
                {classroom.semester}
              </FormField>
              <FormField
                label='Class name'
              >
                {classroom.id}
              </FormField>
              <FormField
                label='Created on'
              >
                {(new Date(classroom.createdAt || 0)).toLocaleString()}
              </FormField>
              <FormField
                label='Last modified'
              >
                {(new Date(classroom.updatedAt || 0)).toLocaleString()}
              </FormField>
            </ColumnLayout>
          </Container>
          <Table
            header={
              <Header
                actions={<Button variant='primary' onClick={() => setStudentModalVisible(true)}>Add</Button>}
              >
                Students
              </Header>
            }
            items={classroom.students}
            columnDefinitions={
              [
                {
                  id: 'name',
                  header: 'Name',
                  cell: item => item.name
                },
                {
                  id: 'alias',
                  header: 'Alias',
                  cell: item => item.alias
                },
                {
                  id: 'button',
                  header: 'Delete',
                  cell: item => <Button loading={processing} onClick={() => {removeStudent(item.alias)}}>Remove</Button>
                }
              ]
            }
            empty='No students added yet.'
          />
          <Modal
            visible={studentModalVisible}
            header='New student'
            onDismiss={() => setStudentModalVisible(false)}
            footer={
              <>
                <Button
                  variant='primary'
                  onClick={addStudent}
                  disabled={!(newStudentAlias && newStudentName)}
                  loading={processing}
                >
                  Add
                </Button>
              </>
            }
          >
            <ColumnLayout columns={1}>
              <FormField
                label='Name'
              >
                <Input
                  value={newStudentName}
                  onChange={({ detail: { value } }) => setNewStudentName(value)}
                />
              </FormField>
              <FormField
                label='Alias'
              >
                <Input
                  value={newStudentAlias}
                  onChange={({ detail: { value } }) => setNewStudentAlias(value)}
                />
              </FormField>
            </ColumnLayout>
          </Modal>
        </>
      }
      navigation={<TeacherNavigation/>}
      headerSelector='#site-header'
      footerSelector='#site-footer'
      toolsHide={true}
    />
  )
}