import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  AppLayout,
  Select,
  Spinner,
} from '@awsui/components-react';
import QuizSection from './QuizSection';

export default function QuizPage(props) {
  // variables
  const { quizId } = useParams();
  const [ quiz, setQuiz ] = useState();
  const [ classroom, setClassroom ] = useState();
  const [ student, setStudent ] = useState();
  const [ progress, setProgress ] = useState();

  // functions
  async function getQuiz() {
    setQuiz((await props.api.get({ path: `/quizzes/${quizId}`})));
  };

  async function getClass() {
    setClassroom((await props.api.get({ path: `/classes/${quiz.classroom}`})));
  };

  async function getProgress(alias) {
    setProgress((await props.api.get({ path: `/quizzes/${quizId}/records/${alias}`})));
  };

  function selectStudent({detail: { selectedOption: { id, original }}}) {
    setStudent(original);
    getProgress(id);
  };

  async function recordProgress(sectionIndex) {
    const record = JSON.parse(JSON.stringify(progress || {}));
    record.resumeSectionIndex = sectionIndex;
    record.completed = sectionIndex >= quiz.sections.length;

    // set start time if provided
    record.startedAt = record.startedAt || Date.now();

    console.log(record);
    await props.api.post({ path: `/quizzes/${quizId}/records/${encodeURIComponent(student.alias)}`, body: record});
    progress.startedAt = record.startedAt;
  }

  // events
  useEffect(() => {
    if (quizId && !quiz) {
      getQuiz();
    }
  }, [quizId]);

  useEffect(() => {
    if (quiz && !classroom) {
      getClass();
    }
  }, [quiz]);

  useEffect(() => {
    console.log('ping');
    console.log(progress);
    if (quiz && !(progress?.resumeSectionIndex)) {
      console.log('pong');
      recordProgress(0);
    }
  }, [progress]);

  useEffect(() => {
    // Check for the test student
    if (classroom && classroom.students && classroom.students[classroom.students.length - 1].alias !== 'test') {
      // Add the test student
      console.log('ping');
      const allStudents = classroom.students.slice();
      allStudents.push({ name: 'Test student', alias: 'test' });

      // update classroom
      setClassroom(prev => {var newClassroom = JSON.parse(JSON.stringify(prev)); newClassroom.students = allStudents; return newClassroom;})
    }
  }, [classroom]);

  // return component
  return (
    <AppLayout
      content={
        quiz && classroom ?
          student ?
            progress ?
              <>
                <h6>{student.name}</h6>
                <h3>{quiz.name}</h3>
                <QuizSection
                  quiz={quiz}
                  progress={progress}
                  student={student}
                  api={props.api}
                  quizId={quizId}
                  recordProgress={recordProgress}
                />
              </>
              :
              <Spinner className='' size='large' />
            :
            <Select
              options={classroom?.students.map(student => {return {id: student.alias, label: student.name, original: student}})}
              placeholder='Select user'
              onChange={selectStudent}
              disabled={student !== undefined}
            />
          :
          <Spinner className='' size='large' />
      }
      headerSelector='#site-header'
      footerSelector='#site-footer'
      toolsHide={true}
      navigationHide={true}
    />
  );
}