import Amplify, { API, Auth } from 'aws-amplify';


export default class ApiClient {

  constructor() {
    Amplify.configure({
      // Auth: {
      //   identityPoolId: ' cognito-idp.us-east-1.amazonaws.com/us-east-1_oz5KvbbmV',
      //   region: 'us-east-1',
      //   userPoolId: 'us-east-1_oz5KvbbmV',
      //   userPoolWebClientId: '7upfv288244ee1364f76h5nf86',
      //   oauth: {
      //     domain: 'https://quizloop.auth.us-east-1.amazoncognito.com',
      //     scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      //     redirectSignIn: 'http://localhost:3000, https://quizloop.ninja.s3.us-east-1.amazonaws.com',
      //     redirectSignOut: "http://localhost:3000, https://quizloop.ninja.s3.us-east-1.amazonaws.com",
      //     responseType: "token"
      //   }
      // },
      API: {
        endpoints: [
          {
            name: 'TeacherAPI',
            endpoint: 'https://p7nc5szh54.execute-api.us-east-1.amazonaws.com/Teacher',
          },
          {
            name: 'StudentAPI',
            endpoint: 'https://by57syhmvf.execute-api.us-east-1.amazonaws.com/Student',
          },
          {
            name: 'ContentBucket',
            endpoint: 'url: https://s3.amazonaws.com/quizloop-content',
          },
        ]
      },
    });
  }

  async prepRequest(params) {
    console.log(Auth);
    // variables
    const request = {
      api: params.api || 'StudentAPI',
      path: params.path,
      headers: { 
        //Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };

    // add optional variables
    if (params.query) {
      request.queryStringParameters = params.query;
    }
    if (params.body) {
      request.body = params.body;
    }

    // return request object
    return request;
  }

  async get(params) {

    // make request
    try {
      // prepare request
      const request = await this.prepRequest(params);
      console.log(request);
      
      // get results
      const result = await API.get(request.api, request.path, request);

      // return results
      return result;
    } catch(err) {
      // log error for debug
      console.log(err);

      // return response
      return err;
    }
  }

  async post(params) {

    // make request
    try {
      // prepare request
      const request = await this.prepRequest(params);
      console.log(request);
      
      // get results
      const result = await API.post(request.api, request.path, request);

      // return results
      return result;
    } catch(err) {
      // log error for debug
      console.log(err);

      // return response
      return err;
    }
  }

  async put(params) {

    // make request
    try {
      // prepare request
      const request = await this.prepRequest(params);
      console.log(request);
      
      // get results
      const result = await API.put(request.api, request.path, request);

      // return results
      return result;
    } catch(err) {
      // log error for debug
      console.log(err);

      // return response
      return err;
    }
  }

  signIn() {
    window.location.href = `https://quizloop.auth.us-east-1.amazoncognito.com/login?response_type=token&client_id=7upfv288244ee1364f76h5nf86&redirect_uri=${window.location.href}`;
  }

}