import React, { useEffect, useState } from 'react';
import {
  Button,
  Cards,
  Flashbar,
} from '@awsui/components-react';
import Video from '../../components/Video';

export default function QuizSection(props) {
  // variables
  const [ showQuestions, setShowQuestions ] = useState(false);
  const [ sectionId, setSectionId ] = useState((props.progress.resumeSectionIndex) || 0);
  const [ questions, setQuestions ] = useState([]);
  const [ selectedItems, setSelectedItems ] = useState([]);
  const [ failedSection, setFailedSection ] = useState(false);

  // functions
  function startQuestions() {
    setShowQuestions(true);
  };

  function shuffleAnswers(questions) {
    const questionsCopy = JSON.parse(JSON.stringify(questions));

    questionsCopy.forEach(question => {
      const shuffledAnswers = [];

      // save correct answer
      question.correctAnswer = question.answers[0];

      // create shuffled list
      while (question.answers.length) {
        const index = Math.floor(Math.random() * question.answers.length);
        shuffledAnswers.push(question.answers[index]);
        question.answers.splice(index, 1);
      }

      // replace answers array
      question.answers = shuffledAnswers;
      question.selectedAnswers = [];
    })

    return questionsCopy;
  }

  function updateAnswersHandler(index) {
    return (event) => {
      setQuestions((prevState) => {const newQuestions = JSON.parse(JSON.stringify(prevState)); newQuestions[index].selectedAnswers = event.detail.selectedItems; return newQuestions;});
    }
  }

  async function checkAnswers() {
    var correct = 0;

    // check if the correct answers pass threshold
    questions.forEach((question) => {
      if (question.selectedAnswers && question.selectedAnswers[0] === question.correctAnswer) {
        question.locked = true;
        correct++;
      } else {
        delete question.selectedAnswers;
      }
    });

    if (correct >= (questions.length * props.quiz?.sections[sectionId].threshold)) {
      props.recordProgress(sectionId + 1);
      setSectionId((prevState) => prevState + 1);
      setFailedSection(false);
    } else {
      setFailedSection(true);
      console.log(props.quiz?.sections[sectionId])
    }

    setShowQuestions(false);
  }

  // triggers
  useEffect(() => {
    if (props.quiz?.sections[sectionId]) {
      setQuestions(shuffleAnswers(props.quiz?.sections[sectionId].questions));
    }
  },[sectionId]);

  // render
  return (
    props.quiz?.sections[sectionId] ?
      <>
        <h4>{props.quiz?.sections[sectionId].title}</h4>
        {failedSection & !showQuestions ? <Flashbar items={[{content: 'Not enough correct answers. Review the material and try again.', type: 'info'}]} /> : ''}
        {
          showQuestions ?
            <>
              {
                questions.map((question, index) => <Cards
                  items={question.answers || []}
                  selectedItems={question.selectedAnswers}
                  selectionType={'single'}
                  onSelectionChange={updateAnswersHandler(index)}
                  isItemDisabled={(answer) => question.locked}
                  cardDefinition={{
                    header: item => item
                  }}
                  cardsPerRow={[{cards: 1}]}
                  header={
                    <div>
                      {question.image ? <img src={`https://quizloop-content.s3.amazonaws.com/quizzes/${props.quizId}/${question.image}`} /> : ''}
                      <div>{question.text}</div>
                    </div>
                  }
                />)
              }
              <Button onClick={checkAnswers} variant='primary'>Done</Button>
            </>
            :
            props.quiz?.sections[sectionId].content.type == 'video' ?
              <Video videoTitle={`${props.quizId}/${props.quiz?.sections[sectionId].content.id}`} onEnd={startQuestions} />
              :
              <> 
                <img src={`https://quizloop-content.s3.amazonaws.com/quizzes/${props.quizId}/${props.quiz?.sections[sectionId].image}`} />
                <Button onClick={startQuestions}>Ready</Button>
              </>
        }
      </>
      :
      <h1>Congratulations!!</h1>
  );
}