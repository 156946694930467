import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  AppLayout,
  Button,
  CodeEditor,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Modal,
  Spinner,
  Table,
  Tabs,
  Textarea,
} from '@awsui/components-react';
import TeacherNavigation from '../../TeacherNavigation';
import StringUtils from '../../../../utilities/StringUtils';

export default function QuizPage(props) {
  // variables
  const { quizId } = useParams();
  const [assets, setAssets] = useState({});
  const assetPrefixLength = `quizzes/${quizId}/`.length;
  const [classroom, setClassroom] = useState({});
  const [contentModalVisible, setContentModalVisible] = useState(false);
  const [flattenedQuestions, setFlattenedQuestions] = useState([]);
  const [jsonErrors, setJsonErrors] = useState([]);
  const [newQuizSections, setNewQuizSections] = useState({});
  const [newContent, setNewContent] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [sectionModalVisible, setSectionsModalVisible] = useState(false);
  const [quiz, setQuiz] = useState({});
  const [quizProgress, setQuizProgress] = useState({});
  const [uploadError, setUploadError] = useState('');

  // functions
  async function getClassData() {
    setClassroom((await props.api.get({ path: `/classes/${quiz.classroom}`})));
  }
  
  async function getQuizAssets() {
    setAssets((await props.api.get({ api: 'TeacherAPI', path: `/quizzes/${quizId}/assets`})).Contents);
  }
  
  async function getQuizData() {
    setQuiz((await props.api.get({ path: `/quizzes/${quizId}`})));
  }
  
  async function getQuizProgress() {
    setQuizProgress((await props.api.get({ path: `/quizzes/${quizId}/records`})));
  }

  async function flattenSections() {
    const flatQuestions = [];

    for(var section of quiz.sections) {
      for(var question of section.questions) {
        flatQuestions.push({
          section: section.title,
          text: question.text,
          image: question.image,
          answers: '"' + question.answers.join('", "') + '"',
          correctAnswer: question.answers[0],
          threshold: section.threshold,
          sectionContentType: section.content.type,
          sectionContentId: section.content.id,
        });
      }
    }

    setFlattenedQuestions(flatQuestions);
  }

  async function updateQuiz() {
    // disable buttons
    setProcessing(true);

    // create new quiz object
    const newQuiz = JSON.parse(JSON.stringify(quiz));

    // update class
    newQuiz.sections = JSON.parse(newQuizSections);
    setQuiz((await props.api.put({ api: 'TeacherAPI', path: `/quizzes/${quizId}`, body: newQuiz })));

    // hide the modal
    setSectionsModalVisible(false);

    // enable buttons
    setProcessing(false);
  }

  async function uploadContent() {
    // disable buttons
    setProcessing(true);

    // upload the file selected
    const data = (await props.api.put({ api: 'TeacherAPI', path: `/quizzes/${quizId}/assets/${newContent.name}`, query: { contentType: newContent.type } }));

    // construct data for upload
    const formData = new FormData();
    Object.entries(data.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", newContent); // must be the last one

    // make API call
    const response = await fetch(data.url, {
      method: "POST",
      body: formData,
    });

    // check response status
    if (response.status >= 300) {
      setUploadError(`Error uploading content code ${response.status}`);
      console.log(response);
    } else {
      // reset error
      setUploadError('');

      // hide the modal
      setContentModalVisible(false);
    }

    // enable buttons
    setProcessing(false);
  }

  // hooks
  useEffect(() => {
    getQuizAssets();
    getQuizData();
    getQuizProgress();
  }, [])

  useEffect(() => {
    if (quiz.classroom) {
      getClassData();
    }

    if (quiz.sections) {
      flattenSections();
      setNewQuizSections(JSON.stringify(quiz.sections, null, 2));
    }
  }, [quiz])

  // return component
  return (
    <AppLayout
      content={
        quiz.name ?
          <ColumnLayout columns={1}>
            <Container>
              <ColumnLayout columns={2}>
                <FormField
                  label='Name'
                >
                  {quiz.name}
                </FormField>
                <FormField
                  label='Classroom'
                >
                  <Link to={`/teacher/classes/${encodeURIComponent(quiz.classroom)}`}>{quiz.classroom}</Link>
                </FormField>
                <FormField
                  label='Created on'
                >
                  {(new Date(classroom.createdAt || 0)).toLocaleString()}
                </FormField>
                <FormField
                  label='Last modified'
                >
                  {(new Date(classroom.updatedAt || 0)).toLocaleString()}
                </FormField>
                <FormField
                  label='Link'
                >
                  <Link to={`/quiz/${encodeURIComponent(quiz.id)}`}>{`https://quizloop.ninja/#/quiz/${quiz.id}`}</Link>
                </FormField>
              </ColumnLayout>
            </Container>
            <Tabs
              tabs={[
                {
                  id: 'questions',
                  label: 'Questions',
                  content:
                    <>
                      <Table
                        header={
                          <Header
                            actions={
                              <>
                                <Button variant='primary' onClick={() => setSectionsModalVisible(true)}>Edit</Button>
                              </>
                            }
                          >
                            Questions
                          </Header>
                        }
                        items={flattenedQuestions}
                        columnDefinitions={
                          [
                            {
                              id: 'section',
                              header: 'Section',
                              cell: item => item.section
                            },
                            {
                              id: 'text',
                              header: 'Text',
                              cell: item => item.text
                            },
                            {
                              id: 'image',
                              header: 'Image',
                              cell: item => <a href={`https://quizloop-content.s3.amazonaws.com/quizzes/${quizId}/${item.image}`}>{item.image}</a>
                            },
                            {
                              id: 'correct',
                              header: 'Correct answer',
                              cell: item => item.correctAnswer
                            },
                            {
                              id: 'answers',
                              header: 'Answers',
                              cell: item => item.answers
                            },
                            {
                              id: 'threshold',
                              header: 'Section threshold',
                              cell: item => item.threshold
                            },
                            {
                              id: 'type',
                              header: 'Section content type',
                              cell: item => item.sectionContentType
                            },
                            {
                              id: 'content',
                              header: 'Section content',
                              cell: item => <a href={`https://quizloop-content.s3.amazonaws.com/quizzes/${quizId}/${item.sectionContentId}`}>{item.sectionContentId}</a>
                            },
                          ]
                        }
                        empty='No questions added yet.'
                      />
                      <Modal
                        visible={sectionModalVisible}
                        header='Edit sections'
                        size='large'
                        onDismiss={() => setSectionsModalVisible(false)}
                        footer={
                          <>
                            <Button
                              variant='primary'
                              onClick={updateQuiz}
                              disabled={!(newQuizSections) || jsonErrors.length || newQuizSections === JSON.stringify(quiz.sections, null, 2)}
                              loading={processing}
                            >
                              Save
                            </Button>
                          </>
                        }
                      >
                        <ColumnLayout columns={1}>
                          <FormField
                            label='Sections JSON'
                            stretch={true}
                          >
                            <CodeEditor
                              value={newQuizSections}
                              onChange={({ detail: { value } }) => setNewQuizSections(value)}
                              language={'json'}
                              ace={window.ace}
                              i18nStrings={{
                                cursorPosition: (row, col) => `row ${row}, col ${col}`
                              }}
                              onValidate={({ detail: { annotations }}) => setJsonErrors(annotations)}
                            />
                          </FormField>
                        </ColumnLayout>
                      </Modal>
                    </>
                },
                {
                  id: 'assets',
                  label: 'Assets',
                  content:
                    <>
                      <Table
                        header={
                          <Header
                            actions={
                              <>
                                <Button variant='primary' onClick={() => setContentModalVisible(true)}>upload content</Button>
                              </>
                            }
                          >
                            Assets
                          </Header>
                        }
                        items={assets}
                        columnDefinitions={
                          [
                            {
                              id: 'file',
                              header: 'File',
                              cell: item => <a href={`https://quizloop-content.s3.amazonaws.com/${item.Key}`}>{item.Key.slice(assetPrefixLength)}</a>
                            },
                            {
                              id: 'size',
                              header: 'Size',
                              cell: item => StringUtils.bytesToSize(item.Size)
                            },
                          ]
                        }
                        empty='No assets added yet.'
                      />
                      <Modal
                        visible={contentModalVisible}
                        header='Upload content'
                        size='large'
                        onDismiss={() => setContentModalVisible(false)}
                        footer={
                          <>
                            <Button
                              variant='primary'
                              onClick={uploadContent}
                              disabled={!(newContent)}
                              loading={processing}
                            >
                              Upload
                            </Button>
                          </>
                        }
                      >
                        <ColumnLayout columns={1}>
                          <FormField
                            label='File'
                            stretch={true}
                            errorText={uploadError}
                          >
                            <input type='file' onChange={event => setNewContent(event.target.files[0])}/>
                          </FormField>
                        </ColumnLayout>
                      </Modal>
                    </>
                },
                {
                  id: 'progress',
                  label: 'Progress',
                  content:
                    <>
                      <Table
                        header={
                          <Header>
                            Student progress
                          </Header>
                        }
                        items={quizProgress}
                        columnDefinitions={
                          [
                            {
                              id: 'id',
                              header: 'Alias',
                              cell: item => item.studentAlias
                            },
                            {
                              id: 'completed',
                              header: 'Completed',
                              cell: item => item.completed ? (new Date(item.completedAt).toLocaleString()) : '-'
                            },
                            {
                              id: 'startedAt',
                              header: 'Started at',
                              cell: item => new Date(item.startedAt).toLocaleString()
                            },
                            {
                              id: 'resumeSection',
                              header: 'Sections Completed',
                              cell: item => item.resumeSectionIndex
                            },
                            {
                              id: 'lastInteraction',
                              header: 'Last interaction',
                              cell: item => (new Date(item.lastUpdated).toLocaleString())
                            },
                          ]
                        }
                        empty='No assets added yet.'
                      />
                    </>
                }
              ]}
            />
          </ColumnLayout>
          :
          <Spinner size='large' />
      }
      navigation={<TeacherNavigation/>}
      headerSelector='#site-header'
      footerSelector='#site-footer'
      toolsHide={true}
    />
  )
}