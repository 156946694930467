import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppLayout,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Table,
} from '@awsui/components-react';
import TeacherNavigation from '../TeacherNavigation';

export default function ClassesPage(props) {
  // variables
  const [classModalVisible, setClassModalVisible] = useState(false);
  const [classrooms, setClassrooms] = useState([]);
  const [newClassName, setNewClassName] = useState(null);
  const [newClassSemester, setNewClassSemester] = useState(null);
  const [processing, setProcessing] = useState(false);

  // functions
  async function getClassData() {
    setClassrooms((await props.api.get({ path: '/classes', api: 'TeacherAPI'})));
  }

  async function addClassroom() {
    // disable buttons
    setProcessing(true);

    // create new classroom object
    const newClassroom = {
      name: newClassName,
      semester: newClassSemester,
      teacher: 'terrywynn',
    };

    // Create classroom
    await props.api.post({ api: 'TeacherAPI', path: '/classes', body: newClassroom });

    // reload classroom list
    await getClassData();

    // reset new classroom fields
    setNewClassName(null);
    setNewClassSemester(null);
    setClassModalVisible(false);

    // enable buttons
    setProcessing(false);
  }

  // hooks
  useEffect(() => {
    getClassData();
  }, [])

  // return component
  return (
    <AppLayout
      content={
        <>
          <Table
            header={
              <Header
                actions={<Button variant='primary' onClick={() => setClassModalVisible(true)}>Add</Button>}
              >
                Classrooms
              </Header>
            }
            loading={!classrooms.length}
            items={classrooms}
            columnDefinitions={
              [
                {
                  id: 'semester',
                  header: 'Semester',
                  cell: item => item.semester
                },
                {
                  id: 'id',
                  header: 'Class name',
                  cell: item => <Link to={`classes/${encodeURIComponent(`${item.semester}|${item.id}`)}`}>{item.id}</Link>
                }
              ]
            }
          />
          <Modal
            visible={classModalVisible}
            header='New class'
            onDismiss={() => setClassModalVisible(false)}
            footer={
              <>
                <Button
                  variant='primary'
                  onClick={addClassroom}
                  disabled={!(newClassName && newClassSemester)}
                  loading={processing}
                >
                  Add
                </Button>
              </>
            }
          >
            <ColumnLayout columns={1}>
              <FormField
                label='Name'
              >
                <Input
                  value={newClassName}
                  onChange={({ detail: { value } }) => setNewClassName(value)}
                />
              </FormField>
              <FormField
                label='Semester'
              >
                <Input
                  value={newClassSemester}
                  onChange={({ detail: { value } }) => setNewClassSemester(value)}
                />
              </FormField>
            </ColumnLayout>
          </Modal>
        </>
      }
      navigation={<TeacherNavigation/>}
      headerSelector='#site-header'
      footerSelector='#site-footer'
      toolsHide={true}
    />
  )
}