import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppLayout, Cards, Table
} from '@awsui/components-react';
import TeacherNavigation from './TeacherNavigation';

export default function TeacherPage(props) {
  // variables

  // functions

  // hooks

  // return component
  return (
    <AppLayout
      content={
        <Cards
          items={[
            {
              label: 'Class rooms',
              destination: 'teacher/classes',
              description: 'Manage the classes and students in them.',
            }
          ]}
          cardDefinition={
            {
              header: item => <Link to={item.destination}>{item.label}</Link>,
              sections: [
                {
                  id: 'description',
                  content: item => item.description,
                }
              ]
            }
          }
        />
      }
      navigation={<TeacherNavigation/>}
      headerSelector='#site-header'
      footerSelector='#site-footer'
      toolsHide={true}
    />
  )
}